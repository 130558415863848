import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./styles/main.css";
import "./styles/media_query.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Branding from "./pages/Services/Branding";
import WebsiteDevelopment from "./pages/Services/WebsiteDevelopment";
import SocialMediaMarketing from "./pages/Services/SocialMediaMarketing";
import SEO from "./pages/Services/SEO";
import InfluencerMarketing from "./pages/Services/InfluencerMarketing";
import PRManagement from "./pages/Services/PRManagement";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail.js";
import Contact from "./pages/Contact";
import { useEffect } from "react";
import Modal from "./components/Modal/index.js";
import NotFound from "./pages/NotFound/index.js";
import CommentsPage from "./pages/CommentsPage/index.js";
import AllServices from "./pages/Services/AllServices/index.js";
import Sitemap from "./pages/Sitemap/index.js";

function App() {
  // any link to click than render to top
  function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
  }
  const isAuthorized = true;
  return (
    <>
      <div id="main">
        <BrowserRouter>
          <ToastContainer />
          <ScrollToTop />
          <Header />
          <div id="page">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/services" element={<AllServices />} />
              <Route exact path="/services/branding" element={<Branding />} />
              <Route
                exact
                path="/services/website-development"
                element={<WebsiteDevelopment />}
              />
              <Route
                exact
                path="/services/social-media-marketing"
                element={<SocialMediaMarketing />}
              />
              <Route exact path="/services/seo" element={<SEO />} />
              <Route
                exact
                path="/services/influencer-marketing"
                element={<InfluencerMarketing />}
              />
              <Route
                exact
                path="/services/pr-management"
                element={<PRManagement />}
              />
              <Route exact path="/about-us" element={<About />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/blog/:slug" element={<BlogDetail />} />
              <Route
                exact
                path="/only-blog-comment-page-for-admin/blog-comments-separate-page"
                element={isAuthorized ? <CommentsPage /> : <Navigate to="/" />}
              />
              <Route exact path="/contact-us" element={<Contact />} />
              <Route exact path="*" element={<NotFound />} />
              <Route exact path="/sitemap" element={<Sitemap />} />
            </Routes>
            <button
              type="button"
              className="btn main-body-enquiry-link"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Enquiry Now
            </button>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
      <Modal /> {/* Render Modal here */}
    </>
  );
}

export default App;
