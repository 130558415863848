import React from "react";
import "./sitemap.css";
import LazyLoad from "react-lazyload";
import MetaDescTag from "../../components/MetaDescTag";
import { Link } from "react-router-dom";

const Sitemap = () => {
  return (
    <>
      <MetaDescTag
        title={"Sitemap | KARN Marketing Warfare LLP"}
        description={
          "We create amazing User-friendly websites in a very good manner to not great hold your clients engaged but also preserve them. Websites designed and advanced through us will seamlessly scale at some stage in most cell gadgets. Whether you are looking for a glowing layout or upgrading a gift website."
        }
      />
      <section className="sitemap-section position-relative">
        <div className="sitemap-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/website-development/website-development-hero-banner.jpg"
            />
            <img
              src="../../assets/website-development/website-development-small-device.jpg"
              alt=""
            />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/website-development/website-development-hero-banner.jpg"
              />
              <img
                src="../../assets/website-development/website-development-small-device.jpg"
                alt="KARN Marketing Website Development Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="sitemap-hero-section-content">
            <div className="h1 m-0">Sitemap</div>
          </div>
        </div>
        <div className="sitemap-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Sitemap</div>
            <ul className="sitemap">
              <li>
                <Link to="/">🏠 Home</Link>
              </li>
              <li>
                <Link to="/services">🛠️ Services</Link>
                <ul>
                  <li>
                    <Link to="/services/branding">🎨 Branding</Link>
                  </li>
                  <li>
                    <Link to="/services/website-development">
                      💻 Website Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/social-media-marketing">
                      📱 Social Media Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/seo">🔍 SEO</Link>
                  </li>
                  <li>
                    <Link to="/services/influencer-marketing">
                      🌟 Influencer Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/pr-management">📢 PR Management</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about-us">ℹ️ About Us</Link>
              </li>
              <li>
                <Link to="/blog">📝 Blog</Link>
              </li>
              <li>
                <Link to="/contact-us">📞 Contact Us</Link>
              </li>
            </ul>
            <div className="sitemap-header-details-row"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitemap;
