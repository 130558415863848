import React from "react";
import "./all-services.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import { allServices, allServicesCardSection } from "../../../staticData/data";
import { Link } from "react-router-dom";

const AllServices = () => {
  return (
    <>
      <MetaDescTag
        title={"Services | KARN Marketing Warfare LLP"}
        description={
          "We create amazing User-friendly websites in a very good manner to not great hold your clients engaged but also preserve them. Websites designed and advanced through us will seamlessly scale at some stage in most cell gadgets. Whether you are looking for a glowing layout or upgrading a gift website."
        }
      />
      <section className="all-services-section position-relative">
        <div className="all-services-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/website-development/website-development-hero-banner.jpg"
            />
            <img
              src="../../assets/website-development/website-development-small-device.jpg"
              alt=""
            />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/website-development/website-development-hero-banner.jpg"
              />
              <img
                src="../../assets/website-development/website-development-small-device.jpg"
                alt="KARN Marketing Website Development Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="all-services-hero-section-content">
            <div className="h1 m-0">Services</div>
          </div>
        </div>
        <div className="all-services-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Services</div>
            <p>
            We generate digital success stories, not just marketing! 🚀 We create businesses that stand out, from creating viral social media campaigns to using SEO to boost your Google rankings. Need a beautiful website? We have you covered! Want graphics that stand out? Words cannot express the impact of our designs. Searching for the magic of influencers? We link you to the appropriate voices. Regardless of the goal—engagement, conversions, or brand dominance—our 360° digital solutions deliver! Together, let's transform clicks into clients and ideas into influence!
            </p>
            <div className="all-services-header-details-row">
              {allServices.map((item, id) => {
                return (
                  <>
                    <Link to={item.allServicesURL} className="all-services-header-details-block" key={id}>
                      <p className="fw-bold text-capitalize">
                        {item.allServicesTitle}
                      </p>
                      <p>{item.allServicesDesc}</p>
                    </Link>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default AllServices;
