/* ===============================================================
header section data
================================================================*/
export const headerData = [
  { id: 1, navTitle: "Branding", navURL: "/services/branding" },
  {
    id: 2,
    navTitle: "Influencer Marketing",
    navURL: "/services/influencer-marketing",
  },
  { id: 3, navTitle: "PR Management", navURL: "/services/pr-management" },
  { id: 4, navTitle: "SEO", navURL: "/services/seo" },
  {
    id: 5,
    navTitle: "Social Media Marketing",
    navURL: "/services/social-media-marketing",
  },
  {
    id: 6,
    navTitle: "Website Development",
    navURL: "/services/website-development",
  },
];

/* ===============================================================
home service card section data
================================================================*/
export const homeServiceCardSection = [
  {
    id: 1,
    serviceTitle: "Branding",
    serviceURL: "/services/branding",
    serviceDescription:
      "A brand desires to be constantly better and tries to be monitored very well to excel in this aggressive worldwide.",
    serviceImgPath: "../../assets/home/services-icon/branding.png",
  },
  {
    id: 2,
    serviceTitle: "Influencer Marketing",
    serviceURL: "/services/influencer-marketing",
    serviceDescription:
      "As one of the top influencer marketing agencies in India, we create smart strategies that provide results.",
    serviceImgPath: "../../assets/home/services-icon/influencer-marketing.png",
  },
  {
    id: 3,
    serviceTitle: "PR Management",
    serviceURL: "/services/pr-management",
    serviceDescription:
      "PR helps establish your brand as a trustworthy and reliable entity through positive media coverage and strategic communication.",
    serviceImgPath: "../../assets/home/services-icon/pr-management.png",
  },
  {
    id: 4,
    serviceTitle: "SEO",
    serviceURL: "/services/seo",
    serviceDescription:
      "KARN Marketing Warfare LLP is the first-rate SEO Company In Navi Mumbai for business across the city.",
    serviceImgPath: "../../assets/home/services-icon/seo.png",
  },
  {
    id: 5,
    serviceTitle: "Social Media Marketing",
    serviceURL: "/services/social-media-marketing",
    serviceDescription:
      "Social media marketing is the method of promoting a service or product via numerous social media platforms.",
    serviceImgPath:
      "../../assets/home/services-icon/social-media-marketing.png",
  },
  {
    id: 6,
    serviceTitle: "Website Development",
    serviceURL: "/services/website-development",
    serviceDescription:
      "We create amazing User-friendly websites in a very good manner to not great hold your clients engaged but also preserve them.",
    serviceImgPath: "../../assets/home/services-icon/website-development.png",
  },
];

/* ===============================================================
home clients logo and websites section data
================================================================*/
export const homeClients = [
  {
    id: 1,
    clientLogo: "../../assets/home/our-clients/casuall-logo-pink.png",
    clientLogoWebsite: "../../assets/home/our-clients/casuall-website.png",
  },
  {
    id: 2,
    clientLogo: "../../assets/home/our-clients/ethinic-indi-logo.png",
    clientLogoWebsite: "../../assets/home/our-clients/casuall-website.png",
  },
  {
    id: 3,
    clientLogo: "../../assets/home/our-clients/karv-logo-black.png",
    clientLogoWebsite: "../../assets/home/our-clients/karv-website.png",
  },
  {
    id: 4,
    clientLogo: "../../assets/home/our-clients/poker-funda-logo-black.png",
    clientLogoWebsite: "../../assets/home/our-clients/poker-funda.png",
  },
];

/* ===============================================================
services section data
================================================================*/
export const allServicesCardSection = [
  {
    id: 1,
    allServicesTitle: "Design",
    allServicesIconPath:
      "../../assets/website-development/website-development-icons/design.png",
  },
  {
    id: 2,
    allServicesTitle: "Development",
    allServicesIconPath:
      "../../assets/website-development/website-development-icons/development.png",
  },
  {
    id: 3,
    allServicesTitle: "Planning",
    allServicesIconPath:
      "../../assets/website-development/website-development-icons/planning.png",
  },
  {
    id: 4,
    allServicesTitle: "Maintenance",
    allServicesIconPath:
      "../../assets/website-development/website-development-icons/maintenance.png",
  },
];
export const allServices = [
  {
    id: 1,
    allServicesTitle: "Branding",
    allServicesDesc:
      "A brand desires to be constantly better and tries to be monitored very well to excel in this aggressive worldwide. As a part of brand advertising, it’s important to build, engage, and persist the logo picture inside the consumer’s mind.",
    allServicesURL: "/services/branding",
  },
  {
    id: 2,
    allServicesTitle: "Influencer Marketing",
    allServicesDesc:
      "As one of the top influencer marketing agencies in India, we create smart strategies that provide results. Influencer marketing has become a major player in the marketing world, and for good reason.",
      allServicesURL: "/services/influencer-marketing",
  },
  {
    id: 3,
    allServicesTitle: "PR Management",
    allServicesDesc:
      "PR in the literal experience of the term manner Public Relations, the artwork of dealing with and controlling the discharge and spread of statistics between an person or an organisation and the common public, With its services like Brand positioning, Media, Strategic counseling, Digital have an impact on, Crisis communique and Reputation control.",
      allServicesURL: "/services/pr-management",
  },
  {
    id: 4,
    allServicesTitle: "SEO",
    allServicesDesc:
      "KARN Marketing Warfare LLP is the first-rate for business across the city. With our SEO services like on-page optimization, Technical optimization, off-page optimization, local SEO, and content optimization KARN will assist businesses to reinforce to find their target audience and help to boost business.",
      allServicesURL: "/services/seo",
  },
  {
    id: 5,
    allServicesTitle: "Social Media Marketing",
    allServicesDesc:
      "Social media marketing is the method of promoting a service or product via numerous social media platforms. You must have effective social media marketing techniques in the vicinity.",
      allServicesURL: "/services/social-media-marketing",
  },
  {
    id: 3,
    allServicesTitle: "Website Development",
    allServicesDesc:
      "We create amazing User-friendly websites in a very good manner to not great hold your clients engaged but also preserve them. Websites designed and advanced through us will seamlessly scale at some stage in most cell gadgets. Whether you are looking for a glowing layout or upgrading a gift website.",
      allServicesURL: "/services/website-development",
  },
];

/* ===============================================================
branding card section data
================================================================*/
export const brandingCardSection = [
  {
    id: 1,
    brandingTitle: "Logo Design",
    brandingIconPath: "../../assets/branding/brands-icons/brand-logo.png",
  },
  {
    id: 2,
    brandingTitle: "Packaging design",
    brandingIconPath: "../../assets/branding/brands-icons/brand-packaging.png",
  },
  {
    id: 3,
    brandingTitle: "Stationary",
    brandingIconPath: "../../assets/branding/brands-icons/brand-stationary.png",
  },
  {
    id: 4,
    brandingTitle: "Brand Strategy and Management",
    brandingIconPath: "../../assets/branding/brands-icons/brand-strategy.png",
  },
];

/* ===============================================================
influencer marketing section data
================================================================*/
export const influencerMarketingCardSection = [
  {
    id: 1,
    influencerMarketingTitle: "Trust and Credibility",
    influencerMarketingIconPath:
      "../../assets/influencer-marketing/influencer-marketing-icons/trust-credibility.png",
  },
  {
    id: 2,
    influencerMarketingTitle: "Targeted Reach",
    influencerMarketingIconPath:
      "../../assets/influencer-marketing/influencer-marketing-icons/target-reach.png",
  },
  {
    id: 3,
    influencerMarketingTitle: "Engagement Power",
    influencerMarketingIconPath:
      "../../assets/influencer-marketing/influencer-marketing-icons/engagement-power.png",
  },
  {
    id: 4,
    influencerMarketingTitle: "Brand Awareness",
    influencerMarketingIconPath:
      "../../assets/influencer-marketing/influencer-marketing-icons/brand-awareness.png",
  },
];

/* ===============================================================
pr management section data
================================================================*/
export const prManagementCardSection = [
  {
    id: 1,
    prManagementTitle: "Builds Brand Credibility and Trust",
    prManagementIconPath:
      "../../assets/pr-management/pr-management-icons/builds-brand.png",
  },
  {
    id: 2,
    prManagementTitle: "Boosts Sales and Leads",
    prManagementIconPath:
      "../../assets/pr-management/pr-management-icons/boost-sales.png",
  },
  {
    id: 3,
    prManagementTitle: "Enhances Online Presence",
    prManagementIconPath:
      "../../assets/pr-management/pr-management-icons/online-presence.png",
  },
];
export const prManagementParaDetails = [
  {
    id: 1,
    prManagementParaDetailsTitle: "Builds Brand Credibility and Trust",
    prManagementParaDetailsDesc:
      "PR helps establish your brand as a trustworthy and reliable entity through positive media coverage and strategic communication.",
  },
  {
    id: 2,
    prManagementParaDetailsTitle: "Boosts Sales and Leads",
    prManagementParaDetailsDesc:
      "By generating positive press and building brand trust, PR can indirectly lead to increased sales and qualified leads.",
  },
  {
    id: 3,
    prManagementParaDetailsTitle: "Improves Relationships with Stakeholders",
    prManagementParaDetailsDesc:
      "PR helps you build strong relationships with investors, partners, customers, and employees.",
  },
  {
    id: 4,
    prManagementParaDetailsTitle: "Increases Brand Awareness and Visibility",
    prManagementParaDetailsDesc:
      "PR gets your brand name out there, reaching a wider audience through press releases, media outreach, and social media engagement.",
  },
  {
    id: 5,
    prManagementParaDetailsTitle: "Shapes Public Perception",
    prManagementParaDetailsDesc:
      "PR allows you to control the narrative around your brand and influence public opinion.",
  },
  {
    id: 6,
    prManagementParaDetailsTitle: "Enhances Online Presence",
    prManagementParaDetailsDesc:
      "PR efforts often go hand-in-hand with digital marketing strategies, improving your website's SEO and overall online visibility.",
  },
];

/* ===============================================================
seo marketing section data
================================================================*/
export const seoCardSection = [
  {
    id: 1,
    seoTitle: "Keyword Research",
    seoIconPath: "../../assets/seo/seo-icons/keyword-research.png",
  },
  {
    id: 2,
    seoTitle: "Website Analysis",
    seoIconPath: "../../assets/seo/seo-icons/website-analysis.png",
  },
  {
    id: 3,
    seoTitle: "Competitors Analysis",
    seoIconPath: "../../assets/seo/seo-icons/competitors-analysis.png",
  },
  {
    id: 4,
    seoTitle: "High Quality Backlinks",
    seoIconPath: "../../assets/seo/seo-icons/high-quality-backlinks.png",
  },
];
export const seoCardParaDetails = [
  {
    id: 1,
    seoCardParaDetailsTitle: "Keyword Research",
    seoCardParaDetailsDesc:
      "Keyword research is one of the most crucial additives of any hit search engine marketing Campaign. Finding and Targeting the right set of Keywords way accelerated possibilities to drive applicable organic site site visitors.",
  },
  {
    id: 2,
    seoCardParaDetailsTitle: "On Page Optimization",
    seoCardParaDetailsDesc:
      "Onpage A vital principle while coping with on-internet web page optimization techniques is to optimize and keep your customers in mind not only Search Engines.",
  },
  {
    id: 3,
    seoCardParaDetailsTitle: "Website Analysis",
    seoCardParaDetailsDesc:
      "Website positive Analysis starts with a Website review, found through Content Analysis, Technical assessment, and on-page analysis through the use of our search engine marketing Experts with the help of top-rated gear like SEMRush, SEOQuake, and Ahrefs.",
  },
  {
    id: 4,
    seoCardParaDetailsTitle: "Content Optimization",
    seoCardParaDetailsDesc:
      "It consists of writing and optimizing the content material with useful and relevant facts for your website that may help clients discover the correct facts they are searching out.",
  },
  {
    id: 5,
    seoCardParaDetailsTitle: "Competitor Analysis",
    seoCardParaDetailsDesc:
      "We as an SEO Agency recognize the importance of competitor analysis in advance before beginning any search engine advertising Campaign. A thorough competitor analysis permits us to provide you with first-class SEO techniques that outrank all of your opposition in your location of interest.",
  },
  {
    id: 6,
    seoCardParaDetailsTitle: "High Quality Backlink",
    seoCardParaDetailsDesc:
      "Creation Backlink is the fave additive of the Search Engines’ algorithms and plays an important function in website rankings.",
  },
];

/* ===============================================================
social media marketing section data
================================================================*/
export const socialMediaMarketingCardSection = [
  {
    id: 1,
    socialMediaMarketingTitle: "Ads",
    socialMediaMarketingIconPath:
      "../../assets/social-media-marketing/social-media-marketing-icons/ads.png",
  },
  {
    id: 2,
    socialMediaMarketingTitle: "Creative",
    socialMediaMarketingIconPath:
      "../../assets/social-media-marketing/social-media-marketing-icons/creative.png",
  },
  {
    id: 3,
    socialMediaMarketingTitle: "Insights",
    socialMediaMarketingIconPath:
      "../../assets/social-media-marketing/social-media-marketing-icons/insights.png",
  },
  {
    id: 4,
    socialMediaMarketingTitle: "Content",
    socialMediaMarketingIconPath:
      "../../assets/social-media-marketing/social-media-marketing-icons/content.png",
  },
];

/* ===============================================================
website development section data
================================================================*/
export const websiteDevelopmentCardSection = [
  {
    id: 1,
    websiteDevelopmentTitle: "Design",
    websiteDevelopmentIconPath:
      "../../assets/website-development/website-development-icons/design.png",
  },
  {
    id: 2,
    websiteDevelopmentTitle: "Development",
    websiteDevelopmentIconPath:
      "../../assets/website-development/website-development-icons/development.png",
  },
  {
    id: 3,
    websiteDevelopmentTitle: "Planning",
    websiteDevelopmentIconPath:
      "../../assets/website-development/website-development-icons/planning.png",
  },
  {
    id: 4,
    websiteDevelopmentTitle: "Maintenance",
    websiteDevelopmentIconPath:
      "../../assets/website-development/website-development-icons/maintenance.png",
  },
];
export const websiteDevelopmentCardParaDetails = [
  {
    id: 1,
    websiteDevelopmentCardParaDetailsTitle: "We Speak Your Language",
    websiteDevelopmentCardParaDetailsDesc:
      "Marketing and generation would possibly look like complex landscapes, however we accept this as true within clean communication.",
  },
  {
    id: 2,
    websiteDevelopmentCardParaDetailsTitle: "Expertise Beyond Code",
    websiteDevelopmentCardParaDetailsDesc:
      "While our team boasts outstanding technical prowess in internet development, our understanding extends ways beyond strains of code.",
  },
  {
    id: 3,
    websiteDevelopmentCardParaDetailsTitle: "Proven Track Record of Success",
    websiteDevelopmentCardParaDetailsDesc:
      "Our portfolio speaks for itself. We have a proven track record of creating high-performing websites for diverse industries, driving measurable results for our clients.",
  },
];

/* ===============================================================
contact faq section data
================================================================*/
export const faqSection = [
  {
    id: 1,
    faqQuestion:
      "What makes KARN Marketing Warfare LLP different from other agencies ?",
    faqAnswer:
      "KARN Marketing Warfare LLP stands out due to our commitment to personalized service, data-driven strategies, and a results-oriented approach. Our team of experts works closely with each client to develop customized solutions that align with their business goals and deliver measurable results.",
  },
  {
    id: 2,
    faqQuestion: "How often will we communicate during a campaign ?",
    faqAnswer:
      "We believe in maintaining open communication with our clients. We provide regular updates and reports on campaign performance and are available for meetings or calls as needed. Our goal is to ensure you’re always informed and involved in the process.",
  },
  {
    id: 1,
    faqQuestion:
      "How long does it take to see results from SEO efforts by an SEO company ?",
    faqAnswer:
      "The timeline for seeing results from SEO efforts by an SEO company can vary depending on a variety of factors, including the competitiveness of your industry, the current state of your website, and the specific strategies employed by the SEO company. In general, however, it can take several months to start seeing significant improvements in your website's ranking and organic traffic. SEO is a long-term investment, and it often requires ongoing effort and monitoring to maintain and improve your website's performance over time.",
  },
  {
    id: 1,
    faqQuestion: "How can a digital marketing agency help my business grow ?",
    faqAnswer:
      "Engaging the services of a digital marketing agency can significantly contribute to the growth of your business in several ways. Firstly, they possess the expertise and resources to develop and execute targeted digital marketing strategies tailored to your business objectives and target audience. This can lead to increased brand visibility, website traffic, and ultimately, higher conversion rates. Furthermore, these agencies stay updated with the latest trends and algorithms in digital marketing platforms, ensuring that your business remains competitive in the online landscape. Additionally, their analytics and reporting capabilities enable them to track and analyze the performance of marketing campaigns, allowing for continuous optimization and improvement.",
  },
];

/* ===============================================================
blog section data
================================================================*/
export const blogs = [
  {
    id: 1,
    slug: "what-is-search-engine-optimization",
    blogTitle:
      "What Is Search Engine Optimization (SEO) , Evolution And Its Benefits ?",
    blogDesc:
      "Search Engine Optimization (SEO) is the process of optimizing a website to improve its visibility and ranking on search engines like Google, Bing, and Yahoo. By optimizing various elements of a website—such as content, keywords, meta tags, and backlinks—SEO helps websites appear higher in search engine results pages (SERPs) for relevant search queries. The ultimate goal of SEO is to attract more organic (non-paid) traffic to a website, leading to increased visibility, credibility, and conversions. The higher your website ranks, the more likely people are to find you when searching for products or services related to your business.",
    blogImg: "../../assets/blog/blogs-post/blog-01.jpg",
    content: [
      {
        type: "heading",
        data: "Some key milestones in the evolution of SEO include:",
      },
      {
        type: "list",
        data: [
          "Mid-1990s: The advent of search engines led website owners to start optimizing their content to rank higher in search results. Early techniques included keyword stuffing and using meta tags.",
          '1996: Larry Page and Sergey Brin, while working on their Ph.D. at Stanford University, developed a search engine called "BackRub," which later became Google. Their innovative approach of ranking pages based on the number and quality of backlinks revolutionized how search engines worked.',
          "1998: Google was officially founded, and its PageRank algorithm became the standard for how search engines ranked websites. This marked the beginning of more sophisticated SEO practices, focusing on link-building, content quality, and relevance.",
        ],
      },
      { type: "heading", data: "Benefits of SEO" },
      {
        type: "list",
        data: [
          "Increased Organic Traffic: SEO improves your website’s ranking on search engines, making it more likely that users will find and visit your site when searching for relevant terms.",
          "Cost-Effective Marketing: Unlike paid advertising, organic traffic generated through SEO doesn’t require ongoing payments, making it a cost-effective long-term strategy.",
          "Enhanced User Experience: SEO involves optimizing website structure, speed, and mobile-friendliness, which enhances the overall user experience, leading to higher engagement and lower bounce rates.",
          "Credibility and Trust: Websites that appear at the top of search results are often perceived as more trustworthy and credible by users, helping to build brand reputation.",
          "Higher Conversion Rates: By attracting more targeted traffic (users actively searching for your products or services), SEO increases the likelihood of conversions, whether that’s sales, sign-ups, or other desired actions.",
          "Competitive Advantage: Effective SEO can help you outrank competitors in search results, ensuring that your business captures more of the market share.",
        ],
      },
      {
        type: "paragraph",
        data: "SEO is a vital digital marketing strategy that helps businesses attract more visitors, improve credibility, and achieve higher conversion rates by optimizing their online presence.",
      },
    ],
  },
];
